import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-work"},[_vm._m(0),_c(VContainer,[_c(VRow,{staticClass:"home-work__contents"},[_c(VCol,{staticClass:"home-work__contents-img",attrs:{"sm":"8"}},[_c(VCarousel,{attrs:{"hide-delimiters":"","height":"auto"}},_vm._l((_vm.workItems),function(workItem,index){return _c(VCarouselItem,{key:index,attrs:{"to":workItem.url}},[_c('img',{staticClass:"img-fluid",attrs:{"src":workItem.src,"alt":""}})])}),1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__title"},[_c('div',{staticClass:"section__title-text"},[_vm._v("02. My Work")])])
}]

export { render, staticRenderFns }