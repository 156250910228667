import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c(VAppBar,{attrs:{"app":"","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = true}}}),_c(VToolbarTitle,[_vm._v("MyPortfolioSite")]),_c(VTabs,_vm._l((_vm.menuItems),function(menuItem,index){return _c(VTab,{key:index,attrs:{"to":menuItem.url}},[_vm._v(" "+_vm._s(menuItem.name)+" ")])}),1)],1),_c(VNavigationDrawer,{attrs:{"fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,_vm._l((_vm.menuItems),function(menuItem,index){return _c(VListItem,{key:index,attrs:{"to":menuItem.url}},[_c(VListItemTitle,[_vm._v(_vm._s(menuItem.name))])],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }