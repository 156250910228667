import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-service"},[_vm._m(0),_c(VContainer,[_c(VRow,{staticClass:"home-service__contents"},[_c(VCol,{attrs:{"md":"3"}},[_c(VCard,{attrs:{"to":"/research#direction","hover":""}},[_c('div',{staticClass:"home-service__card-title"},[_c('h3',[_vm._v("B3")])]),_c('div',{staticClass:"home-service__card-img"},[_c('img',{staticClass:"icon--service",attrs:{"src":require("../assets/img/research-img-1.jpg"),"alt":"ディレクション"}})]),_c('div',{staticClass:"home-service__card-text"},[_c('p',[_vm._v("周辺の手書き文字の綺麗さが手書き文字に与える影響の調査")])])])],1),_c(VCol,{attrs:{"md":"3"}},[_c(VCard,{attrs:{"to":"/service#coding","hover":""}},[_c('div',{staticClass:"home-service__card-title"},[_c('h3',[_vm._v("B4")])]),_c('div',{staticClass:"home-service__card-img"},[_c('img',{staticClass:"icon--service",attrs:{"src":require("../assets/img/coding.jpg"),"alt":"コーディング"}})]),_c('div',{staticClass:"home-service__card-text"},[_c('p',[_vm._v("ShrinkTextbox: Webアンケートの自由記述回答欄サイズ変化による回答の質向上法")])])])],1),_c(VCol,{attrs:{"md":"3"}},[_c(VCard,{attrs:{"to":"/service#design","hover":""}},[_c('div',{staticClass:"home-service__card-title"},[_c('h3',[_vm._v("デザイン")])]),_c('div',{staticClass:"home-service__card-img"},[_c('img',{staticClass:"icon--service",attrs:{"src":require("../assets/img/design.jpg"),"alt":"デザイン"}})]),_c('div',{staticClass:"home-service__card-text"},[_c('p',[_vm._v("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")])])])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__title"},[_c('div',{staticClass:"section__title-text"},[_vm._v("My Research")])])
}]

export { render, staticRenderFns }